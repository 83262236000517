form {

    > .form-group {
        margin-bottom: 1rem;

        label {
            display: block;
            cursor: pointer;
            margin-bottom: .5rem;

            small {
                float: right;
                text-transform: uppercase;
                font-size: 10px;
                margin-top: .35714rem;
                color: #757575;
            }
        }

        .button-group {            
            > .form-control {
                width: calc(100% - 185.45px);
                display: inline-block;
                margin-right: 10px;
            }
            > button {
                float: inherit;
                vertical-align: top;
            }

            &.picker-group {
                > .form-control {
                    width: calc(100% - 125.45px);
                }
            }

            @media (max-width: 551px) {                
                .form-control {
                    display: block;
                    width: 100% !important;
                }
                button {
                    padding: 15px 20px;
                    margin: 20px 0;
                }
            }
        }

        .form-control,
        select.form-control option {
            display: block;
            width: 100%;
            padding: .75rem 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ccc;
            border-radius: .25rem;
            outline: 0;
            transition: all 0.2s ease-in-out;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;                 
        }

        select {
            &:after {
                content: 'ARROW';
            }
        }

        .form-control:focus {
            border-color: #999;
            outline: 0;
        }

        &.error > .form-control,
        &.error > .button-group .form-control {
            border-color: #cc4749;
        }
        
        &.success > .form-control,
        &.success > .button-group .form-control {
            border-color: #008a06;
        }

        &.hidden {
            display: none;
        }
    }
    
    @media (max-width: 551px) {                
        button {
            padding: 15px 20px;
            margin: 20px 0;
        }
    }
}