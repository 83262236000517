.alert {
    position: relative;
    width: 100%;
    padding: 15px 15px 15px 50px;
    background-color: #fdd;
    border-color: #fdd;
    margin-bottom: 25px;
    border-radius: 4px;
    display: none;

    p {
        margin: 0;
    }

    svg {
        position: absolute;
        fill: #cc4749;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    &.active {
        display: block;
    } 
}