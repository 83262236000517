@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
*, :after, :before {
  box-sizing: border-box; }

body {
  font-size: .9rem;
  color: #666;
  font-family: 'Karla', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 551px) {
    main {
      height: inherit; } }

.text-error {
  color: #cc4749; }

.text-center {
  text-align: center; }

.hidden {
  display: none; }

.pull-left {
  float: left !important; }

.pull-right {
  float: right !important; }

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important; }

.alert {
  position: relative;
  width: 100%;
  padding: 15px 15px 15px 50px;
  background-color: #fdd;
  border-color: #fdd;
  margin-bottom: 25px;
  border-radius: 4px;
  display: none; }
  .alert p {
    margin: 0; }
  .alert svg {
    position: absolute;
    fill: #cc4749;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .alert.active {
    display: block; }

.btn {
  padding: .68rem 30px;
  border-radius: 4px;
  outline: 0;
  font-size: .9rem;
  font-family: Montserrat,Arial,Helvetica,sans-serif;
  transition: all 0.2s ease-in-out;
  float: right; }
  .btn.primary {
    background-color: #444;
    border-color: #444;
    color: #fff; }
  .btn.primary:hover {
    background-color: #666;
    border-color: #666; }

.btn:hover {
  cursor: pointer; }

@media (max-width: 551px) {
  .btn {
    display: block;
    width: 100%; } }

form > .form-group {
  margin-bottom: 1rem; }
  form > .form-group label {
    display: block;
    cursor: pointer;
    margin-bottom: .5rem; }
    form > .form-group label small {
      float: right;
      text-transform: uppercase;
      font-size: 10px;
      margin-top: .35714rem;
      color: #757575; }
  form > .form-group .button-group > .form-control {
    width: calc(100% - 185.45px);
    display: inline-block;
    margin-right: 10px; }
  form > .form-group .button-group > button {
    float: inherit;
    vertical-align: top; }
  form > .form-group .button-group.picker-group > .form-control {
    width: calc(100% - 125.45px); }
  @media (max-width: 551px) {
    form > .form-group .button-group .form-control {
      display: block;
      width: 100% !important; }
    form > .form-group .button-group button {
      padding: 15px 20px;
      margin: 20px 0; } }
  form > .form-group .form-control,
  form > .form-group select.form-control option {
    display: block;
    width: 100%;
    padding: .75rem 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: .25rem;
    outline: 0;
    transition: all 0.2s ease-in-out;
    -webkit-appearance: none;
    appearance: none; }
  form > .form-group select:after {
    content: 'ARROW'; }
  form > .form-group .form-control:focus {
    border-color: #999;
    outline: 0; }
  form > .form-group.error > .form-control,
  form > .form-group.error > .button-group .form-control {
    border-color: #cc4749; }
  form > .form-group.success > .form-control,
  form > .form-group.success > .button-group .form-control {
    border-color: #008a06; }
  form > .form-group.hidden {
    display: none; }

@media (max-width: 551px) {
  form button {
    padding: 15px 20px;
    margin: 20px 0; } }

.split-form > .form-group {
  position: relative;
  width: 50%;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 20px 0; }
  .split-form > .form-group label {
    display: block;
    cursor: pointer;
    margin-bottom: .5rem; }
    .split-form > .form-group label small {
      float: right;
      text-transform: uppercase;
      font-size: 10px;
      margin-top: .35714rem;
      color: #757575; }
  .split-form > .form-group .form-control, .split-form > .form-group select.form-control option {
    display: block;
    width: 100%;
    padding: .75rem 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: .25rem;
    outline: 0;
    transition: all 0.2s ease-in-out;
    -webkit-appearance: none;
    appearance: none; }
  .split-form > .form-group .text-error {
    margin: 0; }
  .split-form > .form-group .form-control:focus {
    border-color: #999;
    outline: 0; }
  .split-form > .form-group.error > .form-control,
  .split-form > .form-group.error > .button-group .form-control {
    border-color: #cc4749; }
  .split-form > .form-group.success > .form-control,
  .split-form > .form-group.success > .button-group .form-control {
    border-color: #008a06; }
  .split-form > .form-group.hidden {
    display: none; }
  @media (min-width: 551px) {
    .split-form > .form-group:nth-child(odd) {
      padding-right: 10px; }
    .split-form > .form-group:nth-child(even) {
      padding-left: 10px; } }
  @media (max-width: 551px) {
    .split-form > .form-group {
      display: block;
      width: 100%; } }

@media (max-width: 551px) {
  .split-form > button {
    padding: 15px 20px;
    margin: 20px 0; } }

.login {
  width: 100%; }

.assisted-cart.login {
  max-width: 500px;
  min-width: 250px;
  width: 33%;
  margin: auto; }

.assisted-cart .explanation {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center; }

.recover {
  width: 100%; }

.register {
  max-width: 91.71429rem;
  width: 100%; }

.spinner {
  margin: 0 0 2em;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top; }

.input-spinner {
  position: absolute;
  right: 20px;
  top: 56%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

