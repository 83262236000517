.split-form {

    > .form-group {  
        position: relative;
        width: 50%;
        vertical-align: top;
        display: inline-block;
        padding: 0 0 20px 0;

        label {
            display: block;
            cursor: pointer;
            margin-bottom: .5rem;

            small {
                float: right;
                text-transform: uppercase;
                font-size: 10px;
                margin-top: .35714rem;
                color: #757575;
            }
        }

        .form-control, select.form-control option {
            display: block;
            width: 100%;
            padding: .75rem 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ccc;
            border-radius: .25rem;
            outline: 0;
            transition: all 0.2s ease-in-out;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;                 
        }

        .text-error {
            margin: 0;
        }

        .form-control:focus {
            border-color: #999;
            outline: 0;
        }

        &.error > .form-control,
        &.error > .button-group .form-control {
            border-color: #cc4749;
        }
        
        &.success > .form-control,
        &.success > .button-group .form-control {
            border-color: #008a06;
        }

        &.hidden {
            display: none;
        }

        @media (min-width: 551px) {
            &:nth-child(odd) {
                padding-right: 10px;
            }
            &:nth-child(even) {
                padding-left: 10px;
            }
        }

        @media (max-width: 551px) {
            display: block;
            width: 100%;
        }
    }

    > button {
        @media (max-width: 551px) {
            padding: 15px 20px;
            margin: 20px 0;
        }
    }
}