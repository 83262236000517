*, :after, :before {
    box-sizing: border-box;
}

body {
    font-size: .9rem;
    color: #666;
    font-family: 'Karla', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 551px) {
        height: inherit;
    }
}

.text-error {
    color: #cc4749;
}

.text-center {
    text-align: center;
}

.hidden {
    display: none;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

@import './alert';
@import './button';
@import './form';
@import './split-form';
@import './login';
@import './recover';
@import './register';
@import './spinner';
@import './student-info';
