.spinner {
    margin: 0 0 2em;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
}

.input-spinner {
    position: absolute;
    right: 20px;
    top: 56%;
    transform: translateY(-50%);
}