.login {
    width: 100%;
}

.assisted-cart.login {
    max-width: 500px;
    min-width: 250px;
    width: 33%;
    margin: auto;
}

.assisted-cart .explanation {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center
}
