.btn {
    padding: .68rem 30px;   
    border-radius: 4px;
    outline: 0;
    font-size: .9rem;
    font-family: Montserrat,Arial,Helvetica,sans-serif;
    transition: all 0.2s ease-in-out;
    float: right;

    &.primary {
        background-color: #444;
        border-color: #444;
        color: #fff;
    }
    
    &.primary:hover {
        background-color: #666;
        border-color: #666;
    }
}

.btn:hover {
    cursor: pointer;
}

@media (max-width: 551px) {
    .btn {
        display: block;
        width: 100%;
    }    
}